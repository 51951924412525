<template>
    <!--components/TagList.vue-->
    <NuxtLink 
        v-if="! has_javascript"
        v-for="(tag, index) in tags" 
        :key="index" class="tag" 
        :to="{
            path: `/`, 
            query: {
                tag: tag.replace(/\s/g,'-')
            }
        }" 
        :target="props.target"
        no-prefetch>
        {{ tag }}
    </NuxtLink>
</template>

<script setup>
    const props = defineProps({
        tags: {
            type: Array,
            required: false,
            default: []
        },
        target: {
            type: String,
            required: false,
            default: ''
        }
    });

    let has_javascript = false;
    props.tags.forEach((tag) => {
        if (isJavascriptString(tag,'title')) {
            has_javascript = true;
        }
    })
</script>

<style scoped>
    .tag {
        background-color: #ececec;
        text-decoration: none;
        font-size: small;
        margin: 3px;
        border-radius: 3px;
        border: 1px solid #ddd;
        display: inline-block;
        padding: 0px 10px;
    }
</style>